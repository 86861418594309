/**
 */
export const createGroupBy = (keySelector) => (entries) => {
  return Object.entries(
    entries.reduce((cum, entry) => {
      const key = keySelector(entry)

      return {
        ...cum,
        [key]: [...(cum[key] ?? []), entry],
      }
    }, {}),
  )
}

/**
 */
export const sliceByNumber = (array, number) => {
  const length = Math.ceil(array.length / number)

  return new Array(length)
    .fill()
    .map((_, i) => array.slice(i * number, (i + 1) * number))
}

export const sortBy = (array, selector) => {
  const comparator = (a, b) => {
    if (a === b) {
      return 0
    } else if (a < b) {
      return -1
    } else {
      return 1
    }
  }

  return array.sort((a, b) => {
    return comparator(selector(a), selector(b))
  })
}
