import React from 'react'

import * as LoadTotal from '@arch-log/webapp.usecases/MyProject/List/Remote/Queries/LoadTotal'
import { EmptyError } from './Errors.js'

/**
 */
export const TotalLoader = ({
  children,
  errorOnEmpty = false,
  components,
  handleError,
  ...props
}) => {
  return (
    <LoadTotal.Loader
      components={components}
      handleError={handleError}
      {...props}
    >
      <ValidateTotal errorOnEmpty={errorOnEmpty}>{children}</ValidateTotal>
    </LoadTotal.Loader>
  )
}

/**
 */
const ValidateTotal = ({ errorOnEmpty = false, children }) => {
  const total = LoadTotal.useTotal()

  if (total === 0 && errorOnEmpty) {
    throw new EmptyError()
  }

  return <>{children}</>
}
