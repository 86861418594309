import React from 'react'

import * as MUI from '@mui/material'

/**
 */
export const IconLink = ({ title, children }) => {
  return (
    <MUI.Tooltip title={title}>
      <MUI.Box component={'span'}>{children}</MUI.Box>
    </MUI.Tooltip>
  )
}

/**
 */
export const ProjectLabel = ({ title, children }) => {
  return (
    <MUI.Tooltip title={title}>
      <MUI.Box
        sx={{
          textDecoration: 'underline',
          whiteSpace: 'normal',
          fontSize: '1.1rem',
        }}
      >
        {children}
      </MUI.Box>
    </MUI.Tooltip>
  )
}

/**
 */
export const Address = ({ title, children }) => {
  return (
    <MUI.Tooltip title={title}>
      <MUI.Box
        sx={{
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </MUI.Box>
    </MUI.Tooltip>
  )
}
