import React from 'react'

import { css } from '@emotion/react'

/**
 */
export const Wrapper = ({ children }) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex: 1 0 auto;
      width: 100%;
      background-color: #fafafa;
    `}
  >
    <div
      css={css`
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      {children}
    </div>
  </div>
)

/**
 */
export const Header = ({ children }) => (
  <div
    css={css`
      position: relative;
    `}
  >
    {children}
  </div>
)

/**
 */
export const Body = ({ children }) => (
  <div
    css={css`
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      padding-top: 40px;
      padding-right: 20px;
      padding-left: 20px;
    `}
  >
    {children}
  </div>
)

/**
 */
export const Footer = ({ children }) => (
  <div
    css={css`
      position: sticky;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fafafa;
      padding: 10px;
      z-index: 10;
    `}
  >
    <div css={css``}>{children}</div>
  </div>
)

/**
 */
export const TitleAndTotalProject = ({ children }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        background-color: #c5b8ab;
      `}
    >
      {children}
    </div>
  )
}

/**
 */
export const ResultTable = ({ children }) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
      `}
    >
      {children}
    </div>
  )
}

/**
 */
export const Condition = ({ children }) => {
  return <div css={css``}>{children}</div>
}
