/**
 */
export const None = (entry) => true

/**
 */
export const ByKeyword = (keyword) => (entry) => {
  const searchText = [
    entry.label,
    entry.address.stateOrPrefecture,
    entry.address.city,
    entry.address.street,
  ].join(',')

  return searchText.indexOf(keyword) >= 0
}

/**
 */
export const By = (condition = {}) => {
  if (condition.keyword) {
    return ByKeyword(condition?.keyword)
  } else {
    return None
  }
}
