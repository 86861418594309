import React from 'react'

import { css } from '@emotion/react'

/**
 */
export const IconsCellWrapper = ({ linksNum, children }) => {
  return (
    <div
      css={css`
        display: grid;
        gap: 5px;
        grid-template-columns: repeat(${linksNum}, 25px);
      `}
    >
      {children}
    </div>
  )
}
