import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <div className={styles.Layout_Wrapper}>{children}</div>
)

/**
 */
export const Panes = ({ children }) => (
  <div className={styles.Layout_Panes}>{children}</div>
)

/**
 */
export const Menus = ({ children }) => (
  <div className={styles.Layout_Menus}>{children}</div>
)
