import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useTotal = () => {
  const { total } = useContext()

  return total
}
