import React from 'react'

import * as LoadEntries from '@arch-log/webapp.usecases/MyProject/List/Remote/Queries/LoadEntries'
import { useAsync } from 'react-use'

/**
 */
export const DispatchLoad = () => {
  const [load] = LoadEntries.useLoad()

  const state = useAsync(async () => {
    return await load({
      fetchPolicy: 'network-only',
    })
  }, [])

  if (state?.error) {
    throw state?.error
  }

  return null
}
