import React from 'react'

import * as Usecase from '@arch-log/webapp.usecases/MyProject/List/Components'
import * as ResultTableComponents from './ResultTable/index.js'
import * as Layout from './Layout.jsx'

/**
 */
export const ResultTable = () => {
  return (
    <ResultTableComponents.ErrorBoundary>
      <Usecase.Result.DispatchLoad />
      <Usecase.Result.Loaded>
        <Layout.ResultTable>
          <ResultTableComponents.Table />
        </Layout.ResultTable>
      </Usecase.Result.Loaded>
    </ResultTableComponents.ErrorBoundary>
  )
}
