import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const Provider = ({ entry, children, ...props }) => {
  return (
    <Context.Provider value={{ entry }} {...props}>
      {children}
    </Context.Provider>
  )
}
