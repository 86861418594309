import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useLoad = () => {
  const { load, fetchMore, refetch, loading, entries } = useContext()

  return [
    load,
    {
      fetchMore,
      refetch,
      loading,
      entries,
    },
  ]
}
