import React from 'react'

import { css } from '@emotion/react'

/**
 */
export const Title = ({ children }) => (
  <span
    css={css`
      color: #3f3f3f;
      font-size: 1.1rem;
    `}
  >
    {children}
  </span>
)
