import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import { css } from '@emotion/react'

/**
 */
export const FilterIcon = () => (
  <Icons.Filter
    css={css`
      height: 25px;
      width: 25px;
      margin: auto;
    `}
  />
)

/**
 */
export const VisibilityIcon = () => (
  <Icons.EyeInvisible
    css={css`
      height: 25px;
      width: 25px;
      margin: auto;
    `}
  />
)
