import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query LoadTotal($offset: Int, $size: Int) {
    result: myProjectEntries(offset: $offset, size: $size) {
      total
    }
  }
`
