import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query MyProjectEntries($offset: Int, $size: Int, $filter: Projects_Filter) {
    result: myProjectEntries(offset: $offset, size: $size, filter: $filter) {
      entries {
        id
        slug
        createdAt
        updatedAt
        label
        constructionInfo {
          constructionType
          startYearMonth
          endYearMonth
        }
        propertyInfo {
          basementFloors
          groundFloors
          areaType
          propertyType
        }
        address {
          postalCode
          stateOrPrefecture
          city
          street
          building
        }
        features
      }
      total
    }
  }
`
