import React from 'react'

import { query } from './query.js'
import { Context } from './Context.jsx'
import { useQuery } from '@apollo/client'
import { handleError as defaultHandleError } from '@arch-log/webapp.usecases/utils/GraphQLHelper.js'

/**
 */
export const Loader = ({ handleError = defaultHandleError, components: { Loading = null } = {}, children }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { offset: 0, size: 0 },
  })

  if (error) {
    handleError(error)
  }

  const { total } = React.useMemo(() => {
    return {
      total: data?.result?.total,
    }
  }, [data?.result])

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        total,
      }}
    >
      {children}
    </Context.Provider>
  )
}
