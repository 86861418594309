import React from 'react'

import * as Local from '@arch-log/webapp.usecases/MyProject/List/Local'

/**
 */
export const useVisibilities = () => {
  const [
    visibilities,
    { setVisibility, setVisibilities, getVisibility, getVisibilities },
  ] = Local.FieldVisibilities.useVisibilities()

  return [
    visibilities,
    { setVisibility, setVisibilities, getVisibility, getVisibilities },
  ]
}
