import React from 'react'

import * as ScopeEntries from '@arch-log/webapp.models/Project/Scope/Entries'
import { Loader } from '@arch-log/webapp.atomics/components/Loader'
import { Applier } from '@arch-log/webapp.usecases/MyProject/List/Components/LocalCondition/Applier'

/**
 */
export const Loaded = ({
  components: { Loading = () => <Loader>loading</Loader> } = {},
  children,
}) => {
  const [entries] = ScopeEntries.useEntries()

  if (!entries) {
    return <Loading />
  }

  return <Applier>{children}</Applier>
}
