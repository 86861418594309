import React from 'react'

import { query } from './query.js'
import { Context } from './Context.jsx'
import { useLazyQuery } from '@apollo/client'
import {
  handleError as defaultHandleError,
  createHandleResponse,
} from '@arch-log/webapp.usecases/utils/GraphQLHelper.js'

/**
 */
export const LazyLoader = ({ handleError = defaultHandleError, children }) => {
  const [load, { data, loading, fetchMore, refetch }] = useLazyQuery(query)

  const { entries } = React.useMemo(() => {
    return {
      entries: data?.result?.entries,
    }
  }, [data?.result])

  return (
    <Context.Provider
      value={{
        fetchMore,
        refetch: async (...args) => {
          return await refetch(...args).catch(handleError)
        },
        load: async (...args) => {
          try {
            return await load(...args).then(createHandleResponse())
          } catch (cause) {
            handleError(cause)
          }   
        },
        loading,
        entries,
      }}
    >
      {children}
    </Context.Provider>
  )
}
