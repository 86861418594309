import React from 'react'

import { css } from '@emotion/react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as Literals from './Literals.jsx'

/**
 */
export const CreateNew = ({ ...props }) => {
  return (
    <Links.Project.ToCreateProject
      css={css`
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 0.9rem;
        position: relative;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        pointer-events: all;
        border: 0;
        padding: 2px 7px;
        margin: 0 5px 5px 0;
        height: 45px;
        width: min(100%, 300px);
        color: rgb(255, 255, 255);
        background-color: #e51102;
        border: 1px solid #e51102;
        &:hover {
          color: rgb(255, 255, 255);
          background-color: lighten(#e51102, 10%);
          border-color: lighten(#e51102, 10%);
          text-decoration: none;
        }
        &:disabled {
          pointer-events: none;
          border: none;
          background-color: $theme-color-lightgray;
          color: white;
        }
        &:before {
          position: absolute;
          bottom: 0;
          left: 0px;
          right: 0px;
          height: 2px;
          content: '';
          background: rgba(34, 34, 34, 0.1);
        }
      `}
      {...props}
    >
      <Literals.CreateNew />
    </Links.Project.ToCreateProject>
  )
}
