import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query LoadAuthz($offset: Int, $size: Int) {
    projectAuthorizations(userAlias: "me", offset: $offset, size: $size) {
      entries {
        projectId
        entry {
          role {
            name
          }
        }
      }
    }
  }
`
