import React from 'react'

import * as Base from '@arch-log/webapp.shared/Styled/TabbedListHeader/Containers'
import * as BasePanes from './Panes/index.js'

/**
 */
export const Panes = () => (
  <Base.Panes>
    <BasePanes.Filter />
    <BasePanes.Visibility />
  </Base.Panes>
)
