import React from 'react'

import * as ConditionComponents from './Condition/index.js'
import * as Layout from './ConditionLayout.jsx'

/**
 */
export const Condition = () => {
  return (
    <ConditionComponents.Initializer defaultContent="filter">
      <Layout.Wrapper>
        <Layout.Panes>
          <ConditionComponents.Panes />
        </Layout.Panes>
        <Layout.Menus>
          <ConditionComponents.Menus />
        </Layout.Menus>
      </Layout.Wrapper>
    </ConditionComponents.Initializer>
  )
}
