import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'
import { classNames } from '@arch-log/webapp.lib/CssHelper'
import { Direction } from '@arch-log/webapp.lib/order'

/**
 *
 */
export const DefaultButton = ({
  children,
  value,
  type = 'button',
  ...props
}) => {
  return (
    <button type={type} className={styles.DefaultButton} {...props}>
      {children}
    </button>
  )
}

/**
 *
 */
export const LineButton = ({ children, value, type = 'button', ...props }) => {
  return (
    <button type={type} className={styles.LineButton} {...props}>
      {children}
    </button>
  )
}

/**
 *
 */
export const CancelButton = ({
  children,
  value,
  type = 'button',
  ...props
}) => {
  return (
    <button type={type} className={styles.CancelButton} {...props}>
      {children}
    </button>
  )
}

/**
 *
 */
export const DownloadButton = ({
  children,
  onClick,
  disabled = false,
  icon,
  ...props
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={styles.DownloadButton}
      disabled={disabled}
    >
      {children}
      <div className={styles.DownloadButton_Icon}>{icon}</div>
    </button>
  )
}

/**
 */
export const SortButton = ({
  children,
  onClick,
  isActive,
  direction = 'desc',
  ...props
}) => {
  const icon = isActive ? (
    // FIXME
    direction === Direction.Asc ? (
      <Icons.SortAsc />
    ) : (
      <Icons.SortDesc />
    )
  ) : (
    <div />
  )

  return (
    <button
      type="button"
      className={classNames([
        isActive ? styles.SortButton_Select : styles.SortButton,
        direction,
      ])}
      onClick={onClick}
    >
      {children}
      <div className={styles.SortButton_Icon}>{icon}</div>
    </button>
  )
}

/**
 *
 */
export const VisibleButton = ({
  children,
  value,
  checked = false,
  ...props
}) => {
  const icon = checked ? <Icons.Check /> : <div />
  return (
    <label
      className={checked ? styles.VisibleButton_Checked : styles.VisibleButton}
    >
      <input
        type="checkbox"
        className={styles.Hidden}
        value={value}
        {...props}
        checked={checked}
      />
      {children}
      <div className={styles.VisibleButton_Icon}>{icon}</div>
    </label>
  )
}
