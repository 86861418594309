import React from 'react'
import * as ScopeEntries from '@arch-log/webapp.models/Project/Scope/Entries'

/**
 */
export const useData = () => {
  const [entries] = ScopeEntries.useEntries()

  return React.useMemo(
    () =>
      entries?.map((entry) => {
        return {
          projectId: entry?.slug,
          createdAt: entry?.createdAt,
          label: entry?.label,
          address: `${entry?.address?.stateOrPrefecture ?? ''}${
            entry?.address?.city ?? ''
          }${entry?.address?.street ?? ''}${entry?.address?.building ?? ''}`, // change fulladdress service data
          constructionStartMonth: entry?.constructionInfo?.startYearMonth,
          constructionEndMonth: entry?.constructionInfo?.endYearMonth,
          propertyType: entry?.propertyInfo?.propertyType,
          constructionType: entry?.constructionInfo?.constructionType,
          area: entry?.propertyInfo?.areaType,
          groundFloors: entry?.propertyInfo?.groundFloors,
          basementFloors: entry?.propertyInfo?.basementFloors,
        }
      }),
    [entries],
  )
}
