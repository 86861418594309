import React from 'react'

import { DefaultVisibilities } from './DefaultVisibilities.js'
import { useLocalStorage } from 'react-use'
import { Context } from './Context.jsx'

/**
 */
export const Initializer = ({ children }) => {
  const [visibilities, setVisibilities] = useLocalStorage(
    'ProjectListVisibilities',
    DefaultVisibilities,
  )

  const getVisibility = (key) => {
    return visibilities[key]
  }

  const getVisibilities = () => {
    return visibilities
  }

  const setVisibility = (field, isVisible) => {
    setVisibilities({ ...visibilities, [field]: Boolean(isVisible) })
  }

  return (
    <Context.Provider
      value={{
        visibilities,
        setVisibility,
        setVisibilities,
        getVisibility,
        getVisibilities,
      }}
    >
      {children}
    </Context.Provider>
  )
}
