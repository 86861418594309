import React from 'react'

import * as Base from '@arch-log/webapp.shared/Styled/TabbedListHeader/Containers'
import * as Styled from './Styled.jsx'

/**
 */
export const Visibility = () => (
  <Base.Menu name="visibility">
    <Styled.VisibilityIcon />
  </Base.Menu>
)
