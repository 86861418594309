import React from 'react'

import * as Usecase from '@arch-log/webapp.usecases/MyProject/List/Components'
import * as Layout from './CellLayout.jsx'
import * as Links from './Links.jsx'
import * as Literals from './Literals.jsx'
import * as Icons from './Icons.jsx'
import * as CellValueDecorators from './CellValueDecorators.jsx'

/**
 */
export const QuickLinks = ({ projectId }) => {
  const isVisible = Usecase.Field.QuickLinks.useIsVisible()
  const { formatLinkToolTips } = Literals.useLiterals()

  const links = [
    isVisible('finishingTable') ? (
      <CellValueDecorators.IconLink
        title={formatLinkToolTips('FinishingTable')}
      >
        <Links.ToFinishingTable projectId={projectId}>
          <Icons.FinishingTable />
        </Links.ToFinishingTable>
      </CellValueDecorators.IconLink>
    ) : null,
    isVisible('materialBoard') ? (
      <CellValueDecorators.IconLink title={formatLinkToolTips('MaterialBoard')}>
        <Links.ToMaterialBoard projectId={projectId}>
          <Icons.MaterialBoard />
        </Links.ToMaterialBoard>
      </CellValueDecorators.IconLink>
    ) : null,
  ].filter((v) => v)

  return (
    <Layout.IconsCellWrapper linksNum={links.length}>
      {links.map((link, index) => {
        return <React.Fragment key={index}>{link}</React.Fragment>
      })}
    </Layout.IconsCellWrapper>
  )
}

/**
 */
export const ProjectLabel = ({ projectId, value }) => {
  return (
    <CellValueDecorators.ProjectLabel title={value}>
      <Links.ToAddedProduct projectId={projectId}>{value}</Links.ToAddedProduct>
    </CellValueDecorators.ProjectLabel>
  )
}

/**
 */
export const Address = ({ value }) => {
  return (
    <CellValueDecorators.Address title={value}>
      {value}
    </CellValueDecorators.Address>
  )
}

/**
 */
export const ManageLink = ({ projectId }) => {
  const { formatLinkToolTips } = Literals.useLiterals()

  return (
    <CellValueDecorators.IconLink title={formatLinkToolTips('Manage')}>
      <Links.ToManage projectId={projectId}>
        <Icons.Manage />
      </Links.ToManage>
    </CellValueDecorators.IconLink>
  )
}
