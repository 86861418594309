import React from 'react'

import { useContext } from './useHooks.js'
import { Provider } from './Provider.jsx'

/**
 */
export const Nest = ({ children, ...props }) => {
  const context = useContext()

  if (!context) {
    throw new Error('Cannot provide InScope at not-in-scope')
  }

  const { entries } = context

  return (
    <Provider entries={entries} {...props}>
      {children}
    </Provider>
  )
}
