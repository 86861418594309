import React from 'react'

import * as Filter from './Filter'
import * as Sort from './Sort'

/**
 */
export const Initializer = ({ children }) => {
  return (
    <Filter.Keyword.Initializer>
      <Sort.Initializer>{children}</Sort.Initializer>
    </Filter.Keyword.Initializer>
  )
}
