import { UseCaseError } from '@arch-log/webapp.usecases/utils/UsecaseError.mjs'

/**
 */
export class BaseError extends UseCaseError {
  constructor({ message, cause } = {}) {
    super(message, { cause })

    this.name = 'BaseError'
    this.usecase = 'MyProject'
    this.operation = 'List'
  }
}

/**
 */
export class InitializeError extends BaseError {
  constructor({ message = 'Failed on Initialize', cause = null } = {}) {
    super({ message, cause })

    this.name = 'InitializeError'
  }
}

/**
 */
export class ResultLoadError extends BaseError {
  constructor({ message = 'Failed on ResultLoad', cause = null } = {}) {
    super({ message, cause })

    this.name = 'ResultLoadError'
  }
}

/**
 */
export class EmptyError extends BaseError {
  constructor({ message = 'Failed on Empty', cause = null } = {}) {
    super({ message, cause })

    this.name = 'EmptyError'
  }
}
