import React from 'react'

import { css } from '@emotion/react'

/**
 */
export const Message = ({ children }) => {
  return (
    <div
      css={css`
        font-size: 1.2rem;
        text-align: left;
      `}
    >
      {children}
    </div>
  )
}
