import React from 'react'

import * as Styled from './Styled.jsx'
import * as Literals from './Literals.jsx'

/**
 */
export const Message = () => {
  return (
    <Styled.Message>
      <Literals.Message />
    </Styled.Message>
  )
}
