import React from 'react'

import { css } from '@emotion/react'

/**
 */
export const Title = ({ children }) => (
  <span
    css={css`
      color: #3f3f3f;
      font-size: 1.1rem;
    `}
  >
    {children}
  </span>
)

/**
 */
export const KeywordInput = ({ ...props }) => (
  <input
    css={css`
      width: unquote('min(100%, 400px)');
      margin-bottom: 5px;
      height: 35px;
      padding: 5px 10px;
      //border-radius: 5px;
      border: 1px solid rgb(180, 180, 180);
      color: $inputTextColor;
      &:focus,
      &:hover {
        border-color: rgb(229, 0, 18);
      }
      &::placeholder {
        color: #6a6a6a;
        font-size: 0.9em;
      }
    `}
    type="text"
    {...props}
  />
)
