/**
 */
export const DefaultVisibilities = {
  createdAt: true,
  label: true,
  address: true,
  constructionStartMonth: true,
  constructionEndMonth: true,
  propertyType: true,
  constructionType: true,
  area: true,
  groundFloors: true,
  basementFloors: true,
}
