import React from 'react'

import * as Components from './EmptyError/index.js'
import * as Layout from './Layout.jsx'

/**
 */
export const EmptyError = () => {
  return (
    <Layout.Wrapper>
      <Layout.Body>
        <Components.Message />
      </Layout.Body>
      <Layout.Footer>
        <Components.CreateNew />
      </Layout.Footer>
    </Layout.Wrapper>
  )
}
