import React from 'react'

import * as Entries from '@arch-log/webapp.usecases/MyProject/List/Remote/Queries/LoadEntries'
import * as RemoteCondition from './RemoteCondition/index.js'
import * as LocalCondition from './LocalCondition/index.js'
import * as Field from './Field/index.js'

import { Provider as ScopeProvider } from '@arch-log/webapp.models/Project/Scope/Entries/Provider.jsx'
import { TotalLoader } from './TotalLoader.jsx'
import { createHandleError } from '@arch-log/webapp.usecases/utils/GraphQLHelper.js'
import { InitializeError, ResultLoadError } from './Errors.js'

/**
 */
export const handleInitializeError = createHandleError((cause) => {
  throw new InitializeError({ cause })
})

/**
 */
export const handleResultLoadError = createHandleError((cause) => {
  throw new ResultLoadError({ cause })
})


/**
 */
export const Initializer = ({
  children,
  components: { Loading = () => <>Loading</> } = {},
  errorOnEmpty = false,
  defaultQuickLinks = {},
  ...props
}) => {
  return (
    <RemoteCondition.Initializer>
      <TotalLoader
        components={{
          Loading: () => <Loading />,
        }}
        errorOnEmpty={errorOnEmpty}
        handleError={handleInitializeError}
        {...props}
      >
        <Entries.LazyLoader handleError={handleResultLoadError}>
          <ModelMapper>
            <LocalCondition.Initializer>
              <Field.Visibilities.Initializer>
                <Field.QuickLinks.Initializer
                  defaultQuickLinks={defaultQuickLinks}
                >
                  {children}
                </Field.QuickLinks.Initializer>
              </Field.Visibilities.Initializer>
            </LocalCondition.Initializer>
          </ModelMapper>
        </Entries.LazyLoader>
      </TotalLoader>
    </RemoteCondition.Initializer>
  )
}

/**
 */
export const ModelMapper = ({ children }) => {
  const { entries } = Entries.useContext()

  return <ScopeProvider entries={entries}>{children}</ScopeProvider>
}
