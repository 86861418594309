import React from 'react'

import { css } from '@emotion/react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as Literals from './Literals.jsx'

/**
 */
export const CreateNew = () => (
  <Links.Project.ToCreateProject
    css={css`
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 0.9rem;
      border: 0;
      padding: 2px 7px;
      margin: 0 5px 5px 0;
      position: relative;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      pointer-events: all;
      &:hover {
        text-decoration: none;
      }
      &:disabled {
        pointer-events: none;
        border: none;
        background-color: rgb(180, 180, 180);
        color: white;
      }
      width: 250px;
      height: 40px;
      margin: 0;
      color: rgb(255, 255, 255);
      background-color: #e51102;
      border: 1px solid #e51102;
      &:hover {
        color: rgb(255, 255, 255);
        background-color: lighten(#e51102, 10%);
        border-color: lighten(#e51102, 10%);
      }
    `}
  >
    <Literals.CreateNew />
  </Links.Project.ToCreateProject>
)
