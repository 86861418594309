import React from 'react'

import { css } from '@emotion/react'

/**
 */
export const Wrapper = ({ children }) => (
  <div
    css={css`
      padding-right: 25px;
      padding-left: 25px;
      @media (max-width: 740px) {
        padding-right: 10px;
        padding-left: 10px;
      }
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 200px;
      background-color: #fff;
      padding-top: 50px;
      padding-bottom: 50px;
    `}
  >
    <div css={css``}>{children}</div>
  </div>
)

/**
 */
export const Body = ({ children }) => (
  <div
    css={css`
      display: flex;
      justify-content: center;
      padding: 40px 0;
      color: #6a6a6a;
    `}
  >
    {children}
  </div>
)

/**
 */
export const Footer = ({ children }) => (
  <div
    css={css`
      display: flex;
      justify-content: center;
      padding: 40px 0;
    `}
  >
    {children}
  </div>
)
