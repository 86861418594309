import React from 'react'

import * as Usecase from '@arch-log/webapp.usecases/MyProject/List/Components'
import * as Styled from './Styled.jsx'
import * as Literals from './Literals.jsx'

/**
 */
export const KeywordFilter = () => {
  const [
    condition,
    setCondition,
  ] = Usecase.LocalCondition.Filter.Keyword.useCondition()
  const { keywordPlaceholder } = Literals.useLiterals()

  return (
    <Styled.KeywordInput
      placeholder={keywordPlaceholder}
      value={condition?.keyword}
      onChange={(e) => {
        setCondition({ keyword: e.target.value })
      }}
    />
  )
}
