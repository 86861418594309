import React from 'react'

import * as Base from '@arch-log/webapp.shared/Styled/TabbedListHeader/Containers'

/**
 */
export const Initializer = ({ defaultContent, children }) => {
  return (
    <Base.Initializer defaultContent={defaultContent}>
      {children}
    </Base.Initializer>
  )
}
