import React from 'react'

import * as Scope from '@arch-log/webapp.models/Project/Scope/Entries'
import * as Filter from './Filter/index.js'
import * as Sort from './Sort/index.js'

import { By } from './filter.js'
import { Sorter } from './order.js'
import { useList } from 'react-use'

/**
 */
export const Applier = ({ children }) => {
  const [defaultEntries] = Scope.useEntries()

  const [
    listEntries,
    { set: setEntries, sort: sortEntries, filter: filterEntries },
  ] = useList(defaultEntries)

  const [{ currentKey, currentDirection }] = Sort.useSortBy()
  const [condition] = Filter.Keyword.useCondition()

  React.useEffect(() => {
    setEntries(defaultEntries)
    filterEntries(By(condition))
    sortEntries(Sorter(currentKey, currentDirection))
  }, [defaultEntries, currentKey, currentDirection, condition])

  return <Scope.Nest entries={listEntries}>{children}</Scope.Nest>
}
