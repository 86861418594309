import React from 'react'

import * as Base from '@arch-log/webapp.shared/Styled/TabbedListHeader/Containers'
import * as FilterComponents from './Filter/index.js'
import * as Layout from './FilterLayout.jsx'

/**
 */
export const Filter = () => {
  return (
    <Base.Pane name="filter">
      <Layout.Wrapper>
        <Layout.Header>
          <FilterComponents.Title />
        </Layout.Header>
        <Layout.Body>
          <FilterComponents.KeywordFilter />
        </Layout.Body>
      </Layout.Wrapper>
    </Base.Pane>
  )
}
