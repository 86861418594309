import React from 'react'
import dayjs from 'dayjs'

import * as Usecase from '@arch-log/webapp.usecases/MyProject/List/Components'
import * as ArrayHelper from '@arch-log/webapp.lib/ArrayHelper.js'
import * as Cells from './Cells.jsx'
import * as Literals from './Literals.jsx'

/**
 */
export const useColumns = () => {
  const columns = Usecase.useTableColumns()
  const [_, { getQuickLinks }] = Usecase.Field.QuickLinks.useQuickLinks()
  const { formatHeaderName } = Literals.useLiterals()

  const quickLinks = getQuickLinks()

  const fieldOrder = [
    Usecase.Field.Fields.Label,
    Usecase.Field.Fields.Address,
    Usecase.Field.Fields.ConstructionStartMonth,
    Usecase.Field.Fields.ConstructionEndMonth,
    Usecase.Field.Fields.PropertyType,
    Usecase.Field.Fields.ConstructionType,
    Usecase.Field.Fields.Area,
    Usecase.Field.Fields.GroundFloors,
    Usecase.Field.Fields.BasementFloors,
    Usecase.Field.Fields.CreatedAt,
  ]

  const fieldColumns = ArrayHelper.sortBy(columns, (v) =>
    fieldOrder.findIndex((a) => a === v.name),
  ).map((column) => {
    const { name, label, isVisible } = column

    switch (name) {
      case Usecase.Field.Fields.Label:
        return {
          accessorKey: name,
          enableResizing: true,
          enableSorting: true,
          header: formatHeaderName(label),
          isVisible,
          size: 400,
          sortDescFirst: true,
          Cell: ({ cell }) => {
            return (
              <Cells.ProjectLabel
                projectId={cell.row?.original?.projectId}
                value={cell.row?.original?.label}
              />
            )
          },
        }
      case Usecase.Field.Fields.Address:
        return {
          accessorKey: name,
          enableResizing: true,
          enableSorting: true,
          header: formatHeaderName(label),
          isVisible,
          size: 350,
          sortDescFirst: true,
          sortUndefined: 1,
          Cell: ({ cell }) => {
            return <Cells.Address value={cell.row?.original?.address} />
          },
        }
      case Usecase.Field.Fields.ConstructionStartMonth:
        return {
          accessorKey: name,
          enableResizing: false,
          enableSorting: true,
          header: formatHeaderName(label),
          isVisible,
          size: 150,
          sortDescFirst: true,
          sortUndefined: 1,
          muiTableBodyCellProps: {
            align: 'center',
          },
        }
      case Usecase.Field.Fields.ConstructionEndMonth:
        return {
          accessorKey: name,
          enableResizing: false,
          enableSorting: true,
          header: formatHeaderName(label),
          isVisible,
          size: 250,
          sortDescFirst: true,
          sortUndefined: 1,
          muiTableBodyCellProps: {
            align: 'center',
          },
        }
      case Usecase.Field.Fields.PropertyType:
        return {
          accessorKey: name,
          enableResizing: false,
          enableSorting: true,
          header: formatHeaderName(label),
          isVisible,
          size: 250,
          sortDescFirst: true,
          sortUndefined: 1,
          muiTableBodyCellProps: {
            align: 'center',
          },
        }
      case Usecase.Field.Fields.ConstructionType:
        return {
          accessorKey: name,
          enableResizing: false,
          enableSorting: true,
          header: formatHeaderName(label),
          isVisible,
          size: 250,
          sortDescFirst: true,
          sortUndefined: 1,
          muiTableBodyCellProps: {
            align: 'center',
          },
        }
      case Usecase.Field.Fields.Area:
        return {
          accessorKey: name,
          enableResizing: false,
          enableSorting: true,
          header: formatHeaderName(label),
          isVisible,
          size: 150,
          sortDescFirst: true,
          sortUndefined: 1,
          muiTableBodyCellProps: {
            align: 'center',
          },
        }
      case Usecase.Field.Fields.GroundFloors:
        return {
          accessorKey: name,
          enableResizing: false,
          enableSorting: true,
          header: formatHeaderName(label),
          isVisible,
          size: 150,
          sortDescFirst: true,
          sortUndefined: 1,
          muiTableBodyCellProps: {
            align: 'center',
          },
        }
      case Usecase.Field.Fields.BasementFloors:
        return {
          accessorKey: name,
          enableResizing: false,
          enableSorting: true,
          header: formatHeaderName(label),
          isVisible,
          size: 150,
          sortDescFirst: true,
          sortUndefined: 1,
          muiTableBodyCellProps: {
            align: 'center',
          },
        }
      case Usecase.Field.Fields.CreatedAt:
        return {
          accessorKey: name,
          enableResizing: false,
          enableSorting: true,
          header: formatHeaderName(label),
          isVisible,
          minSize: 200,
          sortDescFirst: true,
          muiTableBodyCellProps: {
            align: 'center',
          },
        }
      default:
        return {
          accessorKey: name,
          enableResizing: true,
          enableSorting: true,
          header: formatHeaderName(label),
          size: 200,
          sortUndefined: 1,
          muiTableBodyCellProps: {
            align: 'center',
          },
        }
    }
  })

  return [
    ...fieldColumns,
    {
      accessorKey: 'quickLinks',
      header: '',
      isVisible: true,
      enableSorting: false,
      enableResizing: false,
      size: Object.keys(quickLinks).length * 30,
      muiTableHeadCellProps: {
        align: 'center',
      },
      Cell: ({ cell }) => {
        return <Cells.QuickLinks projectId={cell.row?.original?.projectId} />
      },
    },
    {
      accessorKey: 'manageLink',
      header: '',
      isVisible: true,
      enableSorting: false,
      enableResizing: false,
      size: 50,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
        sx: {
          borderLeft: '1px solid #eaeaea',
        },
      },
      Cell: ({ cell }) => {
        return <Cells.ManageLink projectId={cell.row?.original?.projectId} />
      },
    },
  ]
}

/**
 */
export const useRows = () => {
  const rows = Usecase.useTableRows()
  const {
    formatPropertyTypes,
    formatConstructionTypes,
    formatAreas,
    formatGroundFloors,
    formatBasementFloors,
  } = Literals.useLiterals()

  return React.useMemo(
    () =>
      rows?.map((row) => {
        return {
          ...row,
          [Usecase.Field.Fields.Label]: row?.label ?? undefined,
          [Usecase.Field.Fields.Address]:
            row?.address === '' ? undefined : row?.address ?? undefined,
          [Usecase.Field.Fields.PropertyType]: row?.propertyType
            ? formatPropertyTypes(row?.propertyType)
            : undefined,
          [Usecase.Field.Fields.ConstructionType]: row?.constructionType
            ? formatConstructionTypes(row?.constructionType)
            : undefined,
          [Usecase.Field.Fields.Area]: row?.area
            ? formatAreas(row?.area)
            : undefined,
          [Usecase.Field.Fields.CreatedAt]: dayjs(
            row?.createdAt,
            'YYYY/MM/DD',
            true,
          ).isValid()
            ? dayjs(row?.createdAt).format('YYYY/MM/DD')
            : undefined,
          [Usecase.Field.Fields.ConstructionStartMonth]: dayjs(
            row?.constructionStartMonth,
            'YYYY/MM',
            true,
          ).isValid()
            ? dayjs(row?.constructionStartMonth).format('YYYY/MM/DD')
            : undefined,
          [Usecase.Field.Fields.ConstructionEndMonth]: dayjs(
            row?.constructionEndMonth,
            'YYYY/MM',
            true,
          ).isValid()
            ? dayjs(row?.constructionEndMonth).format('YYYY/MM/DD')
            : undefined,
          [Usecase.Field.Fields.GroundFloors]: row.groundFloors
            ? formatGroundFloors(row.groundFloors)
            : undefined,
          [Usecase.Field.Fields.BasementFloors]: row.basementFloors
            ? formatBasementFloors(row.basementFloors)
            : undefined,
        }
      }),
    [rows],
  )
}
