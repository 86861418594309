import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 *
 */
export const Title = translated('module.project')('List.Filter.Label')

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation('module.project')

  return {
    keywordPlaceholder: t('List.Filter.ByKeyword.Placeholder'),
  }
}
