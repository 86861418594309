import React from 'react'

import * as Usecase from '@arch-log/webapp.usecases/MyProject/List/Components'
import * as Layout from './Layout.jsx'

import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'
import { CreateNew } from './CreateNew.jsx'
import { ResultTable } from './ResultTable.jsx'
import { Title } from './Title.jsx'
import { Total } from './Total.jsx'
import { Condition } from './Condition.jsx'
import { ErrorBoundary } from './ErrorBoundary.jsx'

/**
 */
export const Root = () => {
  return (
    <ErrorBoundary>
      <Usecase.Initializer
        components={{ Loading: () => <PageLoader>Loading</PageLoader> }}
        errorOnEmpty={true}
        defaultQuickLinks={{
          finishingTable: true,
          materialBoard: true,
          addedProduct: false,
        }}
      >
        <Layout.Wrapper>
          <Layout.Header>
            <Layout.TitleAndTotalProject>
              <Title />
              <Total />
            </Layout.TitleAndTotalProject>
            <Layout.Condition>
              <Condition />
            </Layout.Condition>
          </Layout.Header>

          <Layout.Body>
            <ResultTable />
          </Layout.Body>

          <Layout.Footer>
            <CreateNew />
          </Layout.Footer>
        </Layout.Wrapper>
      </Usecase.Initializer>
    </ErrorBoundary>
  )
}
