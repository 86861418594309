import React from 'react'

import { css } from '@emotion/react'

/**
 */
export const Wrapper = ({ children }) => (
  <div
    css={css`
      padding-right: 25px;
      padding-left: 25px;
      @media (max-width: 740px) {
        padding-right: 10px;
        padding-left: 10px;
      }
      width: 100%;
      padding: 3px 25px;
      padding-top: 3px;
      padding-bottom: 3px;
    `}
  >
    {children}
  </div>
)

/**
 */
export const Header = ({ children }) => (
  <div
    css={css`
      font-size: 1rem;
      font-weight: 700;
      display: block;
      width: 100%;
      margin: 5px 0;
    `}
  >
    {children}
  </div>
)

/**
 */
export const Body = ({ children }) => <>{children}</>
