import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useSortBy = () => {
  const { currentKey, currentDirection, sortBy } = React.useContext(Context)

  return [{ currentKey, currentDirection }, sortBy]
}
