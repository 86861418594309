import React from 'react'

import * as Usecase from '@arch-log/webapp.usecases/MyProject/List/Components'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { useRows, useColumns } from './useHooks.jsx'

import { getSortedRowModel } from './getSortedRowModel.js'

/**
 */
export const Table = () => {
  const rows = useRows()
  const cols = useColumns()
  const columnVisibility = cols.reduce((cum, { accessorKey, isVisible }) => {
    return {
      ...cum,
      [accessorKey]: isVisible,
    }
  }, {})

  const table = useMaterialReactTable({
    columns: cols,
    data: rows,
    enableColumnActions: false,
    initialState: {
      columnPinning: {
        left: ['quickLinks', Usecase.Field.Fields.Label],
        right: ['manageLink'],
      },
      sorting: [
        {
          id: Usecase.Field.Fields.CreatedAt,
          desc: true,
        },
      ],
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
    },
    getSortedRowModel: getSortedRowModel(),
    state: { columnVisibility },
    enableColumnResizing: true,
    enableTopToolbar: false,
    enableStickyHeader: true,
    enableRowVirtualization: true,
    muiPaginationProps: {
      color: 'primary',
      shape: 'rounded',
      showRowsPerPage: false,
      variant: 'outlined',
    },
    muiTablePaperProps: {
      sx: {
        width: '100%',
      },
    },
    muiTableHeadProps: {
      sx: {
        opacity: 1,
      },
    },
    muiTableHeadCellProps: {
      align: 'center',
      sx: {
        opacity: 1,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: '1px solid #eaeaea',
        opacity: 1,
      },
    },
    paginationDisplayMode: 'pages',
    muiTableContainerProps: { sx: { maxHeight: '600px' } },
  })

  return <MaterialReactTable table={table} />
}
