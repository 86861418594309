import React from 'react'

import * as Local from '@arch-log/webapp.usecases/MyProject/List/Local'

/**
 */
export const Initializer = ({ children }) => (
  <Local.FieldVisibilities.Initializer>
    {children}
  </Local.FieldVisibilities.Initializer>
)
