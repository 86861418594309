import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const Initializer = ({ children, ...props }) => {
  const [condition, setCondition] = React.useState({ keyword: '' })

  return (
    <Context.Provider value={{ condition, setCondition }} {...props}>
      {children}
    </Context.Provider>
  )
}
