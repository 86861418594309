import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useVisibilities = () => {
  const {
    visibilities,
    setVisibility,
    setVisibilities,
    getVisibility,
    getVisibilities,
  } = React.useContext(Context)

  return [
    visibilities,
    { setVisibility, setVisibilities, getVisibility, getVisibilities },
  ]
}
