import React from 'react'

import * as Entry from '@arch-log/webapp.models/Project/Scope/Entry'

import { withContextValue } from '@arch-log/webapp.models/utils/withContext.jsx'
import { Context } from './Context.jsx'
import { ChildrenRenderer } from '@arch-log/webapp.models/utils/components/Renderers.jsx'

/**
 */
export const Each = withContextValue(
  Context,
  ({ entries }) => entries,
)(
  ({
    //
    value: entries,
    render: Renderer = ChildrenRenderer,
    ...props
  }) => {
    return entries.map((entry, index) => {
      return (
        <Entry.Provider entry={entry} key={index}>
          <Renderer {...props} />
        </Entry.Provider>
      )
    })
  },
)
