import React from 'react'

import * as Base from '@arch-log/webapp.shared/Styled/TabbedListHeader/Containers'
import * as Components from './Visibility/index.js'
import * as Layout from './VisibilityLayout.jsx'

/**
 */
export const Visibility = () => {
  return (
    <Base.Pane name="visibility">
      <Layout.Wrapper>
        <Layout.Header>
          <Components.Title />
        </Layout.Header>
        <Layout.Body>
          <Components.Fields.Address />
          <Components.Fields.ConstructionStartMonth />
          <Components.Fields.ConstructionEndMonth />
          <Components.Fields.PropertyType />
          <Components.Fields.ConstructionType />
          <Components.Fields.Area />
          <Components.Fields.GroundFloors />
          <Components.Fields.BasementFloors />
          <Components.Fields.CreatedAt />
        </Layout.Body>
      </Layout.Wrapper>
    </Base.Pane>
  )
}
