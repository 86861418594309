import React from 'react'

import * as styles from './styles.module.scss'
import { classNames } from '@arch-log/webapp.lib/CssHelper'

import { Context } from './Context'

/**
 */
export const Container = ({
  children,
  className = styles.Container,
  expandedClassName = styles.Container_Expanded,
  collapsedClassName = styles.Container_Collapsed,
  onClick,
}) => {
  const { isOpened } = React.useContext(Context)

  return (
    <div
      className={classNames([
        className,
        isOpened ? expandedClassName : collapsedClassName,
      ])}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
