import React from 'react'

import { css } from '@emotion/react'

/**
 */
export const Wrapper = ({ children }) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: 100%;
    `}
  >
    {children}
  </div>
)

/**
 */
export const Body = ({ children }) => (
  <div
    css={css`
      display: flex;
      justify-content: center;
      align-items: center;
      color: #6a6a6a;
    `}
  >
    {children}
  </div>
)
