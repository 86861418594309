import React from 'react'

import * as Visibilities from './Visibilities/index.js'
import * as Fields from './Fields.js'

/**
 */
export const useFields = () => {
  const [visibilities] = Visibilities.useVisibilities()

  return [
    {
      name: Fields.CreatedAt,
      label: Fields.CreatedAt,
      isVisible: visibilities?.createdAt,
    },
    {
      name: Fields.Label,
      label: Fields.Label,
      isVisible: visibilities?.label,
    },
    {
      name: Fields.Address,
      label: Fields.Address,
      isVisible: visibilities?.address,
    },
    {
      name: Fields.ConstructionStartMonth,
      label: Fields.ConstructionStartMonth,
      isVisible: visibilities?.constructionStartMonth,
    },
    {
      name: Fields.ConstructionEndMonth,
      label: Fields.ConstructionEndMonth,
      isVisible: visibilities?.constructionEndMonth,
    },
    {
      name: Fields.PropertyType,
      label: Fields.PropertyType,
      isVisible: visibilities?.propertyType,
    },
    {
      name: Fields.ConstructionType,
      label: Fields.ConstructionType,
      isVisible: visibilities?.constructionType,
    },
    {
      name: Fields.Area,
      label: Fields.Area,
      isVisible: visibilities?.area,
    },
    {
      name: Fields.GroundFloors,
      label: Fields.GroundFloors,
      isVisible: visibilities?.groundFloors,
    },
    {
      name: Fields.BasementFloors,
      label: Fields.BasementFloors,
      isVisible: visibilities?.basementFloors,
    },
  ]
}
