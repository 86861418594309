import React from 'react'

import { useTranslation } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation(['app.project'])

  const formatHeaderName = (key, ...options) =>
    t(`ProjectList.Table.Column.Header.${key}`, ...options)

  const formatPropertyTypes = (key, ...options) => {
    if (key === null) {
      return null
    }

    return t(`ProjectList.PropertyTypes.${key}`, ...options)
  }

  const formatConstructionTypes = (key, ...options) => {
    if (key === null) {
      return null
    }

    return t(`ProjectList.ConstructionTypes.${key}`, ...options)
  }

  const formatAreas = (key, ...options) => {
    if (key === null) {
      return null
    }

    return t(`ProjectList.Areas.${key}`, ...options)
  }

  const formatGroundFloors = (key, ...options) => {
    if (key === null) {
      return null
    }

    return key + t('ProjectList.GroundFloors.Suffix', ...options)
  }

  const formatBasementFloors = (key, ...options) => {
    if (key === null) {
      return null
    }

    return key + t('ProjectList.BasementFloors.Suffix', ...options)
  }

  const formatLinkToolTips = (key, ...options) => {
    return t(`ProjectList.Links.ToolTips.${key}`, ...options)
  }

  return {
    formatHeaderName,
    formatPropertyTypes,
    formatConstructionTypes,
    formatAreas,
    formatGroundFloors,
    formatBasementFloors,
    formatLinkToolTips,
  }
}
