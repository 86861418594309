import React from 'react'

import { OrderBy, Direction } from '../order.js'
import { Context } from './Context.jsx'

/**
 */
export const Initializer = ({ children, ...props }) => {
  const [{ key: currentKey, direction: currentDirection }, setOrderBy] =
    React.useState({
      key: OrderBy.CreatedAt,
      direction: Direction.Desc,
    })

  const sortBy = React.useCallback(
    (sortKey, direction) => {
      if (currentKey === sortKey) {
        setOrderBy({
          key: sortKey,
          direction: Direction.oposite(currentDirection),
        })
      } else {
        setOrderBy({ key: sortKey, direction })
      }
    },
    [currentKey, currentDirection, setOrderBy],
  )

  return (
    <Context.Provider
      value={{ currentKey, currentDirection, sortBy }}
      {...props}
    >
      {children}
    </Context.Provider>
  )
}
