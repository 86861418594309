import * as Field from './Field'
import * as Result from './Result'

/**
 */
export const useTableColumns = Field.useFields

/**
 */
export const useTableRows = Result.useData
