import React from 'react'

import * as LoadAuthz from '@arch-log/webapp.usecases/MyProject/List/Remote/Queries/LoadAuthz'
import { useAuthorized } from './useHooks.jsx'

/**
 * <Authorized throwError={ true }/>
 * // Throw Error
 * <Authorized />
 */
export const Authorized = ({
  components: { Loading = () => <>...loading</> } = {},
  projectId,
  always = false,
  children,
}) => (
  <LoadAuthz.Loader components={{ Loading }}>
    <ValidateAuthz projectId={projectId} always={always}>
      {children}
    </ValidateAuthz>
  </LoadAuthz.Loader>
)

/**
 */
const ValidateAuthz = ({ projectId, always = false, children }) => {
  const isAuthorized = useAuthorized({ projectId })

  if (!always && !isAuthorized) {
    return null
  }

  return <>{children}</>
}
