import React from 'react'

import * as Local from '@arch-log/webapp.usecases/MyProject/List/Local'

/**
 */
export const useQuickLinks = Local.QuickLinks.useQuickLinks

/**
 */
export const useIsVisible = Local.QuickLinks.useIsVisible
