import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useCondition = () => {
  const { condition, setCondition } = React.useContext(Context)

  return [condition, setCondition]
}
