import React from 'react'

import * as Local from '@arch-log/webapp.usecases/MyProject/List/Local'

/**
 */
export const Initializer = ({ children, defaultQuickLinks = {} }) => {
  return (
    <Local.QuickLinks.Initializer defaultValues={defaultQuickLinks}>
      {children}
    </Local.QuickLinks.Initializer>
  )
}
