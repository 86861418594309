import React from 'react'

import * as Usecase from '@arch-log/webapp.usecases/MyProject/List/Components'
import { ErrorView } from './ErrorBoundary/ErrorView.jsx'

/**
 */
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  render() {
    switch (true) {
      case this.state.error instanceof Usecase.Errors.ResultLoadError:
        return <ErrorView />
      case this.state.error:
        throw this.state.error
      default:
        return <>{this.props.children}</>
    }
  }
}
