import React from 'react'

import { css } from '@emotion/react'

export {
  Wrapper,
  Panes,
} from '@arch-log/webapp.shared/Styled/TabbedListHeader/Layout'

/**
 */
export const Menus = ({ children }) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: start;
        margin: 0 20px;
        position: absolute;
        bottom: -37px;
        left: 0px;
      `}
    >
      {children}
    </div>
  )
}
