import React from 'react'

import * as Usecase from '@arch-log/webapp.usecases/MyProject/List/Components'
import * as Styled from './Styled.jsx'

/**
 */
export const Total = () => {
  return (
    <Usecase.Result.Total>
      <Styled.Total />
    </Usecase.Result.Total>
  )
}
