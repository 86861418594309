export const CreatedAt = 'createdAt'
export const Label = 'label'
export const Address = 'address'
export const ConstructionStartMonth = 'constructionStartMonth'
export const ConstructionEndMonth = 'constructionEndMonth'
export const PropertyType = 'propertyType'
export const ConstructionType = 'constructionType'
export const Area = 'area'
export const GroundFloors = 'groundFloors'
export const BasementFloors = 'basementFloors'
