import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('app.project')('ProjectList.Visibility.Title')

/**
 */
export const CreatedAt = translated('app.project')(
  'ProjectList.Visibility.CreatedAt',
)

/**
 */
export const Label = translated('app.project')('ProjectList.Visibility.Label')

/**
 */
export const Address = translated('app.project')(
  'ProjectList.Visibility.Address',
)

/**
 */
export const ConstructionStartMonth = translated('app.project')(
  'ProjectList.Visibility.ConstructionStartMonth',
)

/**
 */
export const ConstructionEndMonth = translated('app.project')(
  'ProjectList.Visibility.ConstructionEndMonth',
)

/**
 */
export const PropertyType = translated('app.project')(
  'ProjectList.Visibility.PropertyType',
)

/**
 */
export const ConstructionType = translated('app.project')(
  'ProjectList.Visibility.ConstructionType',
)

/**
 */
export const Area = translated('app.project')('ProjectList.Visibility.Area')

/**
 */
export const GroundFloors = translated('app.project')(
  'ProjectList.Visibility.GroundFloors',
)

/**
 */
export const BasementFloors = translated('app.project')(
  'ProjectList.Visibility.BasementFloors',
)
