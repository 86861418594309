import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Message = translated('app.project')('ProjectList.Errors.Empty.Message')

/**
 */
export const CreateNew = translated('app.project')('ProjectList.CreateNew')
