import React from 'react'

import { query } from './query.js'
import { Context } from './Context.jsx'
import { useQuery } from '@apollo/client'
import { handleError } from '@arch-log/webapp.usecases/utils/GraphQLHelper.js'

/**
 */
export const Loader = ({
  offset = 0,
  size = 10000,
  components: { Loading = null } = {},
  children,
}) => {
  const { data, loading, error } = useQuery(query, {
    variables: { offset, size },
  })

  if (error) {
    handleError(error)
  }

  const { entries } = React.useMemo(() => {
    return {
      entries: data?.projectAuthorizations?.entries,
    }
  }, [data?.projectAuthorizations])

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        entries,
      }}
    >
      {children}
    </Context.Provider>
  )
}
