import React from 'react'

import { css } from '@emotion/react'

/**
 */
export const Title = ({ children }) => (
  <h2
    css={css`
      padding-left: 25px;
      font-weight: 700;
      font-size: 1.3rem;
      margin: 0;
      padding-right: 5px;
      color: #3f3f3f;
      line-height: 50px;
      @media (max-width: 740px) {
        padding-right: 10px;
        padding-left: 10px;
      }
    `}
  >
    {children}
  </h2>
)

/**
 */
export const Total = ({ children }) => {
  return <>({children})</>
}
