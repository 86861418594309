import React from 'react'

import * as BaseMenusComponents from './Menus/index.js'

/**
 */
export const Menus = () => {
  return (
    <>
      <BaseMenusComponents.Filter />
      <BaseMenusComponents.Visibility />
    </>
  )
}
