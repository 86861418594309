import React from 'react'

import { useLocalStorage } from 'react-use'
import { Context } from './Context.jsx'

const Version = '20240318'

/**
 */
export const Initializer = ({ children, defaultValues = {} }) => {
  const [stored, setStored] = useLocalStorage('QuickLinks', {
    version: Version,
    values: defaultValues,
  })

  React.useEffect(() => {
    if (stored?.version !== Version) {
      setStored({
        version: Version,
        values: defaultValues,
      })
    }
  }, [stored])

  const quickLinks = React.useMemo(() => {
    return stored?.values
  }, [])

  const getQuickLinks = () => {
    return stored?.values
  }

  const getQuickLink = (field) => {
    return stored?.values[field]
  }

  const setQuickLinks = (fields) => {
    return setStored({ ...stored, values: fields })
  }

  const setQuickLink = (field, value) => {
    return setStored({
      ...stored,
      values: { ...(stored?.values ?? []), [field]: Boolean(value) },
    })
  }

  return (
    <Context.Provider
      value={{
        quickLinks,
        getQuickLink,
        getQuickLinks,
        setQuickLink,
        setQuickLinks,
      }}
    >
      {children}
    </Context.Provider>
  )
}
