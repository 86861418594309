import React from 'react'

import * as Components from './ErrorView/index.js'
import * as Layout from './Layout.jsx'

/**
 */
export const ErrorView = () => {
  return (
    <Layout.Wrapper>
      <Layout.Body>
        <Components.Message />
      </Layout.Body>
    </Layout.Wrapper>
  )
}
