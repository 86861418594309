import React from 'react'

import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as Partial from 'partials/ProjectListPage'

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}

/**
 */
export const Page = () => {
  return <Partial.Root />
}

export default Page
