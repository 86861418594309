import React from 'react'

import * as Usecase from '@arch-log/webapp.usecases/MyProject/List/Components'
import * as Buttons from '@arch-log/webapp.shared/Buttons'
import * as Literals from './Literals.jsx'

/**
 */
const Base = ({ children, fieldName }) => {
  const [
    visibilities,
    { setVisibility },
  ] = Usecase.Field.Visibilities.useVisibilities()

  return (
    <Buttons.VisibleButton
      onChange={(e) => {
        setVisibility(fieldName, e.target.checked)
      }}
      checked={visibilities[fieldName]}
    >
      {children}
    </Buttons.VisibleButton>
  )
}

/**
 */
export const CreatedAt = () => (
  <Base fieldName={Usecase.Field.Fields.CreatedAt}>
    <Literals.CreatedAt />
  </Base>
)

/**
 */
export const Label = () => (
  <Base fieldName={Usecase.Field.Fields.Label}>
    <Literals.Label />
  </Base>
)

/**
 */
export const Address = () => (
  <Base fieldName={Usecase.Field.Fields.Address}>
    <Literals.Address />
  </Base>
)

/**
 */
export const ConstructionStartMonth = () => (
  <Base fieldName={Usecase.Field.Fields.ConstructionStartMonth}>
    <Literals.ConstructionStartMonth />
  </Base>
)

/**
 */
export const ConstructionEndMonth = () => (
  <Base fieldName={Usecase.Field.Fields.ConstructionEndMonth}>
    <Literals.ConstructionEndMonth />
  </Base>
)

/**
 */
export const PropertyType = () => (
  <Base fieldName={Usecase.Field.Fields.PropertyType}>
    <Literals.PropertyType />
  </Base>
)

/**
 */
export const ConstructionType = () => (
  <Base fieldName={Usecase.Field.Fields.ConstructionType}>
    <Literals.ConstructionType />
  </Base>
)

/**
 */
export const Area = () => (
  <Base fieldName={Usecase.Field.Fields.Area}>
    <Literals.Area />
  </Base>
)

/**
 */
export const GroundFloors = () => (
  <Base fieldName={Usecase.Field.Fields.GroundFloors}>
    <Literals.GroundFloors />
  </Base>
)

/**
 */
export const BasementFloors = () => (
  <Base fieldName={Usecase.Field.Fields.BasementFloors}>
    <Literals.BasementFloors />
  </Base>
)
