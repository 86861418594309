import React from 'react'

import * as Usecase from '@arch-log/webapp.usecases/MyProject/List/Components'
import * as Links from '@arch-log/webapp.shared/Links'

import { css } from '@emotion/react'

/**
 */
const defaultLinkStyles = css`
  cursor: pointer;
  color: #404040;
  font-weight: bold;
  &:hover {
    color: rgb(229, 0, 18);
  }
`

/**
 */
export const ToFinishingTable = ({ projectId, children }) => {
  return (
    <Links.Project.ToFt project={projectId} css={defaultLinkStyles}>
      {children}
    </Links.Project.ToFt>
  )
}

/**
 */
export const ToMaterialBoard = ({ projectId, children }) => {
  return (
    <Links.Project.ToMaterialBoards project={projectId} css={defaultLinkStyles}>
      {children}
    </Links.Project.ToMaterialBoards>
  )
}

/**
 */
export const ToAddedProduct = ({ projectId, children }) => {
  return (
    <Links.Project.ToProducts project={projectId} css={defaultLinkStyles}>
      {children}
    </Links.Project.ToProducts>
  )
}

/**
 */
export const ToManage = ({ projectId, children }) => {
  return (
    <Usecase.Authz.Authorized projectId={projectId}>
      <Links.Project.ToSetting
        project={projectId}
        css={css`
          cursor: pointer;
          color: #b4b4b4;
          font-weight: bold;
          &:hover {
            color: #3f3f3f;
          }
        `}
      >
        {children}
      </Links.Project.ToSetting>
    </Usecase.Authz.Authorized>
  )
}
