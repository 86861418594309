import React from 'react'

import * as LoadTotal from '@arch-log/webapp.usecases/MyProject/List/Remote/Queries/LoadTotal'

/**
 *
 * ```
 *  <TotalDecorator><Styled.Total/></TotalDecorator>
 *
 * ```
 */
export const Total = ({ children }) => {
  const total = LoadTotal.useTotal()

  const value = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      children: total,
      value: total,
    })
  })

  return value
}
