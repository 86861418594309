import React from 'react'

/**
 * Context to hold state of switchable content
 */
export const Context = React.createContext(undefined)

/**
 * Container of the switchable contents
 */
export const Initializer = ({ children, default: defaultValue }) => {
  const [selected, setSelected] = React.useState(defaultValue)

  return (
    <Context.Provider value={{ selected, setSelected }}>
      {children}
    </Context.Provider>
  )
}

export const Provider = Initializer

/**
 * Switchable Content.
 * Wrapped content(children) only be rendered when this is selected
 */
export const Content = ({ children, name }) => {
  const { selected } = React.useContext(Context)

  if (selected !== name) {
    return null
  }

  return <>{children}</>
}
