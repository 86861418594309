import React from 'react'

import { classNames } from '@arch-log/webapp.lib/CssHelper'

import * as styles from './styles.module.scss'

/**
 */
export const MenuList = ({ children }) => (
  <div className={styles.MenuList}>{children}</div>
)

/**
 */
export const MenuListItem = ({
  children,
  onClick,
  isActive = false,
  activeClassName = styles.MenuListItem_Active,
  className = styles.MenuListItem,
}) => (
  <div
    className={classNames([
      className,
      isActive ? activeClassName : null,
    ])}
    onClick={onClick}
  >
    {children}
  </div>
)

/**
 */
export const Pane = ({ children }) => (
  <div className={styles.Pane}>{children}</div>
)
