import React from 'react'

import * as LoadAuthz from '@arch-log/webapp.usecases/MyProject/List/Remote/Queries/LoadAuthz'
import * as Roles from '@arch-log/webapp.usecases/MyProject/List/roles.js'
import * as BooleanHelper from '@arch-log/webapp.lib/BooleanHelper'

/**
 * Either one of the given roleName matched, then true
 */
export const useAuthorized = ({ projectId }) => {
  const { entries } = LoadAuthz.useContext()

  const entry = (() => {
    return entries.find((entry) => entry?.projectId === projectId)?.entry
  })()

  return BooleanHelper.arrayOr(
    [Roles.Owner, Roles.Admin].flat(),
    (role) => entry?.role?.name === role,
  )
}
