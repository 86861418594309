import React from 'react'

import * as Literals from './Literals.jsx'
import * as Styled from './Styled.jsx'

/**
 */
export const Title = () => (
  <Styled.Title>
    <Literals.Title />
  </Styled.Title>
)
