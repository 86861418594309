import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const Provider = ({ children, entries }) => {
  return (
    <Context.Provider
      value={{
        entries,
      }}
    >
      {children}
    </Context.Provider>
  )
}
