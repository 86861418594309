import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useQuickLinks = () => {
  const {
    quickLinks,
    getQuickLink,
    getQuickLinks,
    setQuickLink,
    setQuickLinks,
  } = React.useContext(Context)

  return [
    quickLinks,
    { getQuickLink, getQuickLinks, setQuickLink, setQuickLinks },
  ]
}

/**
 */
export const useIsVisible = () => {
  const { quickLinks } = React.useContext(Context)

  const isVisible = (field) => {
    return quickLinks?.[field] ?? false
  }

  return isVisible
}
