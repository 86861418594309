import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import { css } from '@emotion/react'

const iconStyles = css`
  height: 25px;
  width: 25px;
`

/**
 */
export const FinishingTable = () => <Icons.ToFt css={iconStyles} />

/**
 */
export const MaterialBoard = () => <Icons.MaterialBoards css={iconStyles} />

/**
 */
export const AddedProduct = () => <Icons.Link css={iconStyles} />

/**
 */
export const Manage = () => <Icons.ProjectSetting css={iconStyles} />
