import * as utils from '@tanstack/table-core'

/**
 */
export const getSortedRowModel = () => (table) => {
  return utils.memo(
    () => [table.getState().sorting, table.getPreSortedRowModel()],
    (sorting, rowModel) => {
      if (!rowModel.rows.length || !(sorting != null && sorting.length)) {
        return rowModel
      }
      const sortingState = table.getState().sorting
      const sortedFlatRows = []

      // Filter out sortings that correspond to non existing columns
      const availableSorting = sortingState.filter((sort) => {
        var _table$getColumn
        return (_table$getColumn = table.getColumn(sort.id)) == null
          ? void 0
          : _table$getColumn.getCanSort()
      })
      const columnInfoById = {}
      availableSorting.forEach((sortEntry) => {
        const column = table.getColumn(sortEntry.id)
        if (!column) return
        columnInfoById[sortEntry.id] = {
          sortUndefined: column.columnDef.sortUndefined,
          invertSorting: column.columnDef.invertSorting,
          sortingFn: column.getSortingFn(),
        }
      })
      const sortData = (rows) => {
        // This will also perform a stable sorting using the row index
        // if needed.
        const sortedData = rows.map((row) => ({
          ...row,
        }))
        sortedData.sort((rowA, rowB) => {
          for (let i = 0; i < availableSorting.length; i += 1) {
            var _sortEntry$desc
            const sortEntry = availableSorting[i]
            const columnInfo = columnInfoById[sortEntry.id]
            const isDesc =
              (_sortEntry$desc = sortEntry == null ? void 0 : sortEntry.desc) !=
              null
                ? _sortEntry$desc
                : false
            let sortInt = 0

            // All sorting ints should always return in ascending order
            if (columnInfo.sortUndefined) {
              const aValue = rowA.getValue(sortEntry.id)
              const bValue = rowB.getValue(sortEntry.id)
              const aUndefined = aValue === undefined
              const bUndefined = bValue === undefined
              if (aUndefined || bUndefined) {
                sortInt =
                  aUndefined && bUndefined
                    ? 0
                    : aUndefined
                    ? columnInfo.sortUndefined
                    : -columnInfo.sortUndefined

                return sortInt
              }
            }
            if (sortInt === 0) {
              sortInt = columnInfo.sortingFn(rowA, rowB, sortEntry.id)
            }

            // If sorting is non-zero, take care of desc and inversion
            if (sortInt !== 0) {
              if (isDesc) {
                sortInt *= -1
              }
              if (columnInfo.invertSorting) {
                sortInt *= -1
              }
              return sortInt
            }
          }
          return rowA.index - rowB.index
        })

        // If there are sub-rows, sort them
        sortedData.forEach((row) => {
          var _row$subRows
          sortedFlatRows.push(row)
          if ((_row$subRows = row.subRows) != null && _row$subRows.length) {
            row.subRows = sortData(row.subRows)
          }
        })
        return sortedData
      }
      return {
        rows: sortData(rowModel.rows),
        flatRows: sortedFlatRows,
        rowsById: rowModel.rowsById,
      }
    },
    utils.getMemoOptions(table.options, 'debugTable', 'getSortedRowModel', () =>
      table._autoResetPageIndex(),
    ),
  )
}
