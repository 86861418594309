import { comparatorByProperty, Direction } from '@arch-log/webapp.lib/order'

import dayjs from 'dayjs'

/**
 * Enum of OrderBy Key
 */
export const OrderBy = {
  Label: 'label',
  PropertyType: 'property_type',
  ConstructionStartMonth: 'construction_start_month',
  ConstructionEndMonth: 'construction_end_month',
  ConstructionType: 'construction_type',
  CreatedAt: 'created_at',
}

/**
 */
export const SortKeys = [
  OrderBy.Label,
  OrderBy.CreatedAt,
  OrderBy.ConstructionType,
  OrderBy.PropertyType,
  OrderBy.ConstructionStartMonth,
  OrderBy.ConstructionEndMonth,
]

/**
 *
 */
export const Sorter = (key, direction = Direction.Desc) => {
  switch (key) {
    case OrderBy.Label:
      return comparatorByProperty((entry) => entry?.label, direction)
    case OrderBy.CreatedAt:
      return comparatorByProperty((entry) => entry?.createdAt, direction)
    case OrderBy.PropertyType:
      return comparatorByProperty(
        (entry) => entry?.propertyInfo?.propertyType,
        direction,
      )
    case OrderBy.ConstructionType:
      return comparatorByProperty(
        (entry) => entry?.constructionInfo?.constructionType,
        direction,
      )
    case OrderBy.ConstructionStartMonth:
      return comparatorByProperty(
        (entry) =>
          entry?.constructionInfo?.startYearMonth
            ? dayjs(`${entry.constructionInfo.startYearMonth}-01`)
            : null,
        direction,
      )
    case OrderBy.ConstructionEndMonth:
      return comparatorByProperty(
        (entry) =>
          entry?.constructionInfo?.endYearMonth
            ? dayjs(`${entry.constructionInfo.endYearMonth}-01`)
            : null,
        direction,
      )
    default:
      return (a, b) => 0
  }
}

/**
 */
export { Direction }
