import React from 'react'

//export const Default = () => {
//
//  return (
//    <Wrapper>
//      <PaneSection>
//        <Containeres.Panes>
//        <Container.Pane name="">
//          ...
//        </Container.Pane>
//        <Container.Pane name="">
//          ...
//        </Container.Pane>
//        </Containeres.Panes>
//      </PaneSection>
//      <MenuSection>
//      <Containers.Menu>
//        <Container.Menu name="">
//        </Container.Menu>
//      </Containers.Menu>
//      </MenuSection>
//    </Wrapper>
//  )
//}

import * as Collapse from '@arch-log/webapp.atomics/components/Collapse'
import * as SwitchableContent from '@arch-log/webapp.atomics/components/SwitchableContent'

import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 */
export const Initializer = ({
  children,
  defaultCollapsed = false,
  defaultContent,
}) => {
  return (
    <Collapse.Initializer collapsed={defaultCollapsed}>
      <SwitchableContent.Initializer default={defaultContent}>
        {children}
      </SwitchableContent.Initializer>
    </Collapse.Initializer>
  )
}

/**
 */
export const Panes = ({ ...props }) => (
  <Collapse.Container
    className={styles.TabContent}
    expandedClassName={styles.Expanded}
    collapsedClassName={styles.Collapsed}
    {...props}
  />
)

/**
 */
export const Pane = ({ name, children, ...props }) => {
  return (
    <SwitchableContent.Content {...props} name={name}>
      {children}
    </SwitchableContent.Content>
  )
}

/**
 */
export const Menus = ({ children }) => {
  return <Styled.MenuList>{children}</Styled.MenuList>
}

export const Menu = ({ name, children }) => {
  const { isOpened, open, toggle } = React.useContext(Collapse.Context)
  const { selected, setSelected } = React.useContext(SwitchableContent.Context)

  return (
    <Styled.MenuListItem
      onClick={() => {
        if (selected !== name) {
          setSelected(name)
          open()
        } else {
          toggle()
        }
      }}
      isActive={selected === name && isOpened}
    >
      {children}
    </Styled.MenuListItem>
  )
}
