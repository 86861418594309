import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useContext = () => {
  return React.useContext(Context)
}

/**
 */
export const useEntries = () => {
  const { entries } = useContext()

  return [entries]
}
